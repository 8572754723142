<template>
  <div class="productBox">
    <ul>
      <li>
        <el-card class="box-card">
          <h5>期魔方量化平台</h5>
          <p>
            期魔方是一款应用于国内期货市场的量化交易软件,
            软件内含python量化编程, javascript量化编程, 多元化回测等功能,
            也内置策略超市、 盘手训练等模块方便大家学习。
          </p>
          <div class="ms">
            <el-icon><Monitor /></el-icon>配置要求:Win7(64位)及以上操作系统，2核4G以上配置
          </div>
          <div class="ms">
            <el-icon><Paperclip /></el-icon>版本信息:v0.9.2
          </div>
          <div class="btns">
            <el-button type="primary" @click="uplowd">软件下载</el-button>
            <el-button>使用说明</el-button>
          </div>
        </el-card>
      </li>
    </ul>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  data() {
    return {
      config: '信息获取中…',
      version: '信息获取中…'
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    uplowd() {
      location.href = "https://academy.qmfquant.com/setup_hot_update.exe";
    },
    get() {
      // axios.get(`https://academy.qmfquant.com/api/getQmfs`).then((response) => {
      //   this.config = response.data['config']
      //   this.version = response.data['version']
      //   console.log('[config]',this.config,'[version]',this.version)
      // });
    },
  },
};
</script>

<style>
@media (min-width: 1200px) {
  .productBox {
    width: 1200px;
    min-height: 534px;
    margin: 0 auto;
    padding-top: 30px;
  }
  .productBox ul li {
    width: 50%;
  }
  .productBox ul li h5 {
    font-size: 20px;
    color: #301330;
    margin-bottom: 10px;
  }
  .productBox ul li p {
    font-size: 14px;
    color: #665f66;
    line-height: 25px;
  }
  .productBox ul li .ms {
    font-size: 14px;
    color: #665f66;
    margin-top: 15px;
    display: flex;
    align-items: center;
  }
  .productBox ul li div .el-icon {
    color: #135de9;
    font-size: 17px;
    margin-right: 5px;
  }
  .productBox ul li .btns {
    margin-top: 20px;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .productBox {
    width: 98vw;
    min-height: 100%;
    margin: 0 auto;
    padding-top: 2rem;
  }
  .productBox ul li {
    width: 50%;
  }
  .productBox ul li h5 {
    font-size: 1.4rem;
    color: #301330;
    margin-bottom: 10px;
  }
  .productBox ul li p {
    font-size: 0.9rem;
    color: #665f66;
    line-height: 1.5rem;
  }
  .productBox ul li .ms {
    font-size: 0.9rem;
    color: #665f66;
    margin-top: 0.9rem;
    display: flex;
    align-items: center;
  }
  .productBox ul li div .el-icon {
    color: #135de9;
    font-size: 0.95rem;
    margin-right: 0.3rem;
  }
  .productBox ul li .btns {
    margin-top: 1rem;
  }
}
@media (max-width: 768px) {
  .productBox {
    width: 98vw;
    margin: 0 auto;
    padding-top: 1rem;
    margin-bottom: 1rem;
  }
  .productBox ul li {
    width: 100%;
  }
  .productBox ul li h5 {
    font-size: 1rem;
    color: #301330;
    margin-bottom: 0.5rem;
  }
  .productBox ul li p {
    font-size: 0.7rem;
    color: #665f66;
    line-height: 1rem;
  }
  .productBox ul li .ms {
    font-size: 0.7rem;
    color: #665f66;
    margin-top: 0.9rem;
    display: flex;
    align-items: center;
  }
  .productBox ul li div .el-icon {
    color: #135de9;
    font-size: 0.95rem;
    margin-right: 0.3rem;
  }
  .productBox ul li .btns {
    margin-top: 0.7rem;
  }
  .productBox ul li .btns .el-button {
    width: 4rem;
    font-size: 0.7rem;
    height: 1.5rem;
  }
}
</style>